:root {
  --footer-height: 30;
}

.footer {
  background-color: var(--color-background);
  display: block;
  height: calc(var(--footer-height) * 1px);
}

.footer p {
  color: var(--color-foreground);
  display: inline-block;
  float: right;
  font-size: 12px;
  margin: 0;
  padding: 8px 14px 0 0;
}

.footer .back-to-top {
  cursor: pointer;
  float: initial;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 0 0 20px;
  width: 200px;
}
.footer .back-to-top:hover {
  color: var(--color-accent);
}
