:root {
  --color-background: rgb(44, 54, 64);
  --color-background-light: rgb(166, 178, 189);
  --color-foreground: rgb(255, 255, 255);
  /* 234,236,238 */
  --color-text: rgb(234, 236, 238);
  --color-tonic: rgb(0, 102, 153);
  --color-mediant: rgb(0, 119, 180);
  --color-dominant: rgb(51, 153, 204);
  --color-accent: rgb(255, 204, 51);
  --color-accent-backup: rgb(72, 88, 104);
}

body,
html {
  background-color: var(--color-background);
}

p {
  color: var(--color-text);
}

#app {
  background: fixed;
  background-image: linear-gradient(
    var(--color-foreground) 0,
    var(--color-foreground) calc(var(--header-height) * 1px),
    var(--color-background)
  );
}