.banner {
  /* background: url("../../../images/profile_cover2@2x.jpg") no-repeat top center/cover; */
  /* background-attachment: fixed; */
  /* box-shadow: 0 -30px 30px -30px rgba(0, 0, 0, 0.5) inset, 0 30px 30px -30px rgba(0, 0, 0, 0.5) inset; */
  /* background-color: lightgray; */
  display: block;
  /* background-image: linear-gradient(var(--color-foreground) , var(--color-background)); */
  height: 300px;
  padding: 0 20px;
  padding-top: 150px;
  text-align: center;
  margin: 0 auto;
  max-width: 400px;
}

#banner-title {
  border-bottom: 2px solid var(--color-background);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* transform: translateY(50px); */
}

#banner-title h1 {
  flex: none;
  margin: 0;

  font-size: 30px;
  font-weight: normal;
  color: var(--color-background);
}

#banner-title p {
  flex: 1;
  margin: 3px 6px;
  padding: 0;

  color: var(--color-accent);
  font-size: 16px;
  font-weight: bold;
}

.banner .prefix {
  text-align: right;
}

.banner .suffix {
  text-align: left;
}

.banner .name {
  /* border-top: 2px solid var(--color-background); */
  display: block;
  font-size: 52px;
  color: var(--color-background);
  margin: 0 auto;
  padding: 0;
}
